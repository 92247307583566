import { isThaiLang } from '@/lib/utils';

export const checkSpecialCharacterWithoutThai = (_: any, value = '') => {
  if (!isThaiLang(value) && !/^[a-zA-Z0-9 ]+$/.test(value)) {
    return Promise.reject();
  }

  return Promise.resolve();
};

export const checkSpecialCharacter = (_: any, value = '') => {
  if (!/^[a-zA-Z0-9]+$/.test(value)) {
    return Promise.reject();
  }

  return Promise.resolve();
};
