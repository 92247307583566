'use client';

import { Drawer, Modal } from 'antd';
import React from 'react';

import useIsMobile from '@/components/hooks/useScreenSize';

const MyModalDrawer = (props: any) => {
  const { children } = props;
  const isMobileClient = useIsMobile();
  return (
    <div>
      {isMobileClient ? (
        <Drawer
          styles={{
            header: {
              borderRadius: '16px',
              borderBottom: 0,
            },
            body: {
              paddingTop: 0,
            },
            content: {
              background: '#EAEAEA',
            },
          }}
          // Drawer props for mobile
          {...props}
        >
          {children}
        </Drawer>
      ) : (
        <Modal
          styles={{
            content: {
              background: '#EAEAEA',
            },
          }}
          {...props}
        >
          {children}
        </Modal>
      )}
    </div>
  );
};

export default MyModalDrawer;
