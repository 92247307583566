/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable unused-imports/no-unused-vars */
'use client';
import {
  ArrowsAltOutlined,
  CloseOutlined,
  ExportOutlined,
  FullscreenOutlined,
  ShrinkOutlined,
} from '@ant-design/icons';
import { Modal } from 'antd';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { usePathname } from 'next/navigation';
import React, { useEffect, useRef, useState } from 'react';

import { toggleFullscreen } from '@/lib/utils';

import { openGameProvider } from '@/components/core/OpenGame';
import useIsMobile from '@/components/hooks/useScreenSize';

import { useGameModalStore } from '@/store/zustand';

const initBounce = {
  left: 0,
  top: 0,
  bottom: 0,
  right: 0,
};
const initClassName = {
  className: ' !container-pc !min-h-fit !w-[90%]',
  content: 'h-[80vh] !w-full !p-0 !bg-transparent',
};
const initMobileClassName = {
  className: '!min-h-screen !w-[95%] mx-auto',
  content:
    'h-full !w-full !p-0 !bg-transparent !top-[0] !fixed left-0 right-0 bottom-0 inset-0  ',
};

const GameContainer: React.FC<{ url?: string }> = () => {
  const ref = useRef(null);
  const [loaded, setLoaded] = useState(false);
  const isMobile = useIsMobile();
  const [disabled, setDisabled] = useState(true);
  // const controls = useDragControls();
  const [gameContainerClass, setGameContainerClass] = useState(initClassName);
  const { setGameInfo, gameInfo, isLargeSize, setLargeSize } =
    useGameModalStore();

  const pathname = usePathname();
  // const [changes, setChanges] = useState(0);

  const closeModal = () => {
    setTimeout(() => {
      setGameInfo({});
    }, 200);
  };

  useEffect(() => {
    setGameContainerClass(() => {
      if (!isLargeSize) {
        return {
          className: ` !min-h-[80vh] !h-[87vh] !w-[450px] !fixed !left-[2%] !top-[60%]`,
          content: ' !h-[300px] !w-full !p-0 !bg-transparent',
        };
      }
      return {
        ...initClassName,
      };
    });
  }, [isLargeSize]);

  const handleShrink = () => {
    if (isLargeSize) {
      setLargeSize(false);
    } else {
      setLargeSize(true);
    }
  };

  return (
    <div className={clsx(isMobile && 'hidden')}>
      <Modal
        maskClosable={false}
        closeIcon={null}
        getContainer='body'
        footer={null}
        forceRender
        wrapProps={{ a: 1 }}
        keyboard={false}
        prefixCls='gameContainerModal'
        className={clsx(
          '',
          isMobile
            ? initMobileClassName.className
            : gameContainerClass.className
        )}
        wrapClassName={clsx(!isLargeSize && 'wrapLargeSmall')}
        mask={isLargeSize}
        classNames={{
          content: isMobile
            ? initMobileClassName.content
            : gameContainerClass.content,
          body: '!h-full !w-full !bg-black',
          mask: 'backdrop-blur-md',
          header: '!bg-transparent !mb-0',
          footer: '!rounded-lg',
        }}
        title={
          <div
            onPointerDown={(event: any) => {
              // controls.start(event);
            }}
            style={{
              touchAction: 'none',
              width: '100%',
              cursor: 'move',
              background: isLargeSize ? 'transparent' : '#00000080',
            }}
            className='cursor-hand !rounded-t-lg'
          >
            <div className='flex items-center gap-2 rounded-t-lg bg-[#ffffff33] px-2 py-1 '>
              <div className='vw:text-16 md:text-20 mr-auto font-bold text-white'>
                {gameInfo?.name && gameInfo?.name}
              </div>
              {/* {!isLargeSize && (
                <div className='cursor-hand flex h-[45px] w-[45px] items-center justify-center rounded-full bg-[#06050d33] hover:!brightness-50 active:!brightness-75'>
                  <DragOutlined className='text-20' />
                </div>
              )} */}
              {!isMobile && (
                <div
                  className='cursor-hand flex h-[45px] w-[45px] items-center justify-center rounded-full bg-[#06050d33] hover:!brightness-50 active:!brightness-75'
                  onClick={handleShrink}
                >
                  {isLargeSize ? (
                    <ShrinkOutlined className='text-20' />
                  ) : (
                    <ArrowsAltOutlined className='text-20' />
                  )}
                </div>
              )}

              <div
                className='cursor-hand flex h-[45px] w-[45px] items-center justify-center rounded-full bg-[#06050d33] hover:!brightness-50 active:!brightness-75'
                onClick={() => {
                  openGameProvider(gameInfo, null, true);
                }}
              >
                <ExportOutlined className='text-20' />
              </div>
              {!isMobile && (
                <div
                  className='cursor-hand flex h-[45px] w-[45px] items-center justify-center rounded-full bg-[#06050d33] hover:!brightness-50 active:!brightness-75'
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleFullscreen(ref?.current);
                  }}
                >
                  <FullscreenOutlined className='text-20' />
                </div>
              )}
              <div
                className='cursor-hand flex h-[45px] w-[45px] items-center justify-center rounded-full bg-[#06050d33] hover:!brightness-50 active:!brightness-75'
                onClick={closeModal}
              >
                <CloseOutlined className='text-20' />
              </div>
            </div>
          </div>
        }
        onCancel={closeModal}
        open={!!gameInfo?.src}
        modalRender={(modal) => (
          <>
            {/* <Draggable
              disabled={disabled}
              bounds={bounds}
              nodeRef={draggleRef}
              onStart={(event, uiData) => onStart(event, uiData)}
            > */}
            {/* <div ref={draggleRef}>{modal}</div> */}
            {/* </Draggable> */}
            <motion.div
              // style={
              //   isLargeSize
              //     ? {
              //         transform: '',
              //       }
              //     : {}
              // }
              dragListener={false}
              // dragControls={controls}
              drag
              // dragTransition={{ bounceStiffness: 0, bounceDamping: 1 }}
              dragConstraints={{
                top: 10,
                right: window.innerWidth / 2 - 300,
                left: -window.innerWidth / 2 + 300,
                bottom: window.innerHeight / 2 + 320,
              }}
            >
              {modal}
              {/* draggable element  */}
            </motion.div>
          </>
        )}
      >
        {!loaded && (
          <div className='flex h-full w-full items-center justify-center'>
            <img
              className='mx-auto'
              src='/img/common/loading.svg'
              alt='loading'
            />
          </div>
        )}
        <iframe
          onMouseOver={() => {
            if (disabled) {
              setDisabled(false);
            }
          }}
          onMouseOut={() => {
            setDisabled(true);
          }}
          className={clsx(!loaded && 'hidden', 'no-drag h-full w-full')}
          onLoad={() => {
            setLoaded(true);
          }}
          ref={ref}
          id='game-container'
          src={gameInfo?.src}
        />
      </Modal>
    </div>
  );
};

export default GameContainer;
