import { Form, Input } from 'antd';
import clsx from 'clsx';

import ButtonCore from '@/components/core/Button';
import useClientTranslation from '@/components/hooks/useClientTranslation';

const LoginForm = (props: any) => {
  const { onFinish, showModalForgot, loading, className } = props;
  const { t } = useClientTranslation('HomePage');
  return (
    <div
      className={clsx(
        'form-login-modal rounded-b-xl bg-[#FFF] md:flex md:flex-row md:rounded-xl',
        className
      )}
    >
      <div className='flex flex-col md:w-full md:pt-3'>
        <Form
          className='md:flex md:h-full md:flex-col md:px-8'
          name='normal_login'
          onFinish={onFinish}
          requiredMark={false}
          layout='vertical'
        >
          <Form.Item
            normalize={(value) => value.trim()}
            label={
              <div className='text-12 text-white opacity-70'>
                {t('modal.User_name')}
              </div>
            }
            labelCol={{
              className: '!-mb-1.5',
            }}
            className='!mb-2'
            name='username'
            rules={[
              {
                required: true,
                message: t('modal.Please_enter_username'),
              },
            ]}
          >
            <Input
              className='!text-16 h-10 !rounded-lg'
              placeholder={t('modal.User_name_placeholder')}
            />
          </Form.Item>

          <Form.Item
            normalize={(value) => value.trim()}
            className='!mb-2 '
            labelCol={{
              className: '!-mb-1.5',
            }}
            label={
              <div className='text-12  text-white opacity-70'>
                {t('modal.Password')}
              </div>
            }
            name='password'
            rules={[
              {
                required: true,
                message: t('modal.Please_enter_your_password'),
              },
            ]}
          >
            <Input.Password
              className='!text-16 h-10 !rounded-lg'
              height='48'
              type='password'
              placeholder={t('modal.Password')}
              iconRender={(visible) => {
                if (!visible) {
                  return (
                    <svg
                      className='mr-3'
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                    >
                      <g clipPath='url(#clip0_611_5518)'>
                        <path
                          d='M12 6.5C15.79 6.5 19.17 8.63 20.82 12C19.17 15.37 15.8 17.5 12 17.5C8.2 17.5 4.83 15.37 3.18 12C4.83 8.63 8.21 6.5 12 6.5ZM12 4.5C7 4.5 2.73 7.61 1 12C2.73 16.39 7 19.5 12 19.5C17 19.5 21.27 16.39 23 12C21.27 7.61 17 4.5 12 4.5ZM12 9.5C13.38 9.5 14.5 10.62 14.5 12C14.5 13.38 13.38 14.5 12 14.5C10.62 14.5 9.5 13.38 9.5 12C9.5 10.62 10.62 9.5 12 9.5ZM12 7.5C9.52 7.5 7.5 9.52 7.5 12C7.5 14.48 9.52 16.5 12 16.5C14.48 16.5 16.5 14.48 16.5 12C16.5 9.52 14.48 7.5 12 7.5Z'
                          fill='#8A8A8A'
                        />
                      </g>
                      <defs>
                        <clipPath id='clip0_611_5518'>
                          <rect width='24' height='24' fill='white' />
                        </clipPath>
                      </defs>
                    </svg>
                  );
                }
                return (
                  <svg
                    className='mr-3'
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                  >
                    <g clipPath='url(#clip0_659_5722)'>
                      <path
                        d='M12 5.99995C15.79 5.99995 19.17 8.12995 20.82 11.5C20.23 12.72 19.4 13.77 18.41 14.62L19.82 16.03C21.21 14.8 22.31 13.26 23 11.5C21.27 7.10995 17 3.99995 12 3.99995C10.73 3.99995 9.51 4.19995 8.36 4.56995L10.01 6.21995C10.66 6.08995 11.32 5.99995 12 5.99995ZM10.93 7.13995L13 9.20995C13.57 9.45995 14.03 9.91995 14.28 10.49L16.35 12.56C16.43 12.22 16.49 11.86 16.49 11.49C16.5 9.00995 14.48 6.99995 12 6.99995C11.63 6.99995 11.28 7.04995 10.93 7.13995ZM2.01 3.86995L4.69 6.54995C3.06 7.82995 1.77 9.52995 1 11.5C2.73 15.89 7 19 12 19C13.52 19 14.98 18.71 16.32 18.18L19.74 21.6L21.15 20.19L3.42 2.44995L2.01 3.86995ZM9.51 11.37L12.12 13.98C12.08 13.99 12.04 14 12 14C10.62 14 9.5 12.88 9.5 11.5C9.5 11.45 9.51 11.42 9.51 11.37V11.37ZM6.11 7.96995L7.86 9.71995C7.63 10.27 7.5 10.87 7.5 11.5C7.5 13.98 9.52 16 12 16C12.63 16 13.23 15.87 13.77 15.64L14.75 16.62C13.87 16.86 12.95 17 12 17C8.21 17 4.83 14.87 3.18 11.5C3.88 10.07 4.9 8.88995 6.11 7.96995Z'
                        fill='#626262'
                      />
                    </g>
                    <defs>
                      <clipPath id='clip0_659_5722'>
                        <rect width='24' height='24' fill='white' />
                      </clipPath>
                    </defs>
                  </svg>
                );
              }}
            />
          </Form.Item>
          <div className='!text-main-pri vw:mb-6 vw:mt-[13px] mr-auto cursor-pointer text-center md:mx-auto md:mb-[24px] md:mt-[13px]'>
            <span onClick={() => showModalForgot()}>
              {t('modal.Forget_password')}?
            </span>
          </div>
          <div className=''>
            <Form.Item className='!mb-2 mt-8 md:!mb-0 md:mt-auto md:w-full'>
              <div className='flex flex-row md:!mr-1.5'>
                <ButtonCore
                  loading={loading}
                  type='primary'
                  size='large'
                  htmlType='submit'
                  label={t('modal.Login')}
                  className='md:!text-13 vw:!text-14 !primary-btn w-full !py-[11px] !text-black'
                />
              </div>
            </Form.Item>
            {/* <ButtonCore
              size='large'
              type='primary'
              className='w-full !bg-white md:!ml-1.5 md:w-1/2'
              onClick={showModalRegister}
            >
              <span className='text-neutral-7 md:text-13 ml-2 cursor-pointer font-bold md:ml-0'>
                {t('modal.Dont_have_an_account')}
              </span>
            </ButtonCore> */}
          </div>
        </Form>
      </div>
    </div>
  );
};

export default LoginForm;
