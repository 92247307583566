'use client';

import { Form, Input, message } from 'antd';
import { useState } from 'react';

import {
  getOptPhone,
  getOptTelegram,
  sendNewPassBySMS,
  //   sendOptTelegram,
} from '@/lib/apis';
import Notification from '@/lib/notification';
import { formatThaiPhoneNumber } from '@/lib/utils';

import ButtonCore from '@/components/core/Button';
import useClientTranslation from '@/components/hooks/useClientTranslation';

enum ETypeTab {
  SMS = 1,
  TELE = 2,
}
function ForgotPassMobileForm() {
  const [
    tab,
    //  setTab
  ] = useState<number>(ETypeTab.SMS);
  const { t } = useClientTranslation('HomePage');
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState<number>(1);
  const [step1Value, setStep1Value] = useState<any>({});
  const [form] = Form.useForm();

  const onFinish = async (_values: any) => {
    //Set Cookie to step2 not set
    setLoading(true);
    const fn = tab === ETypeTab.TELE ? getOptTelegram : getOptPhone;
    const { data, code, msg } = await fn({
      ..._values,
      phone: formatThaiPhoneNumber(_values.phone, '0'),
    });
    if (code === 200) {
      setStep(2);
      if (_values) {
        setStep1Value({
          ..._values,
          phone: formatThaiPhoneNumber(_values.phone, '0'),
        });
      }
      // props.onClose();
    } else {
      message.error(msg || data?.message);
    }
    setLoading(false);
  };

  const onFinishSMSStep2 = async (_values: any) => {
    setLoading(true);
    const { data, code, msg } = await sendNewPassBySMS({
      ..._values,
      ...step1Value,
    });
    if (code === 200) {
      Notification.success({
        title: `<div class='text-center text-14 md:text-20'>${t(
          'modal.password_changed_successfully'
        )}</div>`,
        timer: 1600,
      });

      setTimeout(() => {
        window.popup?.login();
      }, 1650);
    } else {
      message.error(msg || data?.message);
    }
    setLoading(false);
  };

  if (step === 3) {
    return (
      <div className=''>
        <div className='my-2 flex flex-row items-center rounded-lg bg-green-200 py-3'>
          <div className='ml-4 text-left font-normal text-[#000000]'>
            {t('modal.success_message')}
          </div>
        </div>
        <div className='my-3 flex flex-row items-center rounded-lg bg-[#edf3f1] py-3'>
          <div className='bg-secondary-1 ml-2 flex h-[31px] w-[31px] items-center justify-center rounded px-4 py-3 font-bold text-white'>
            1
          </div>
          <div className='ml-4 text-left font-normal text-[#000000]'>
            {t('modal.reset_password_link_sent')}
          </div>
        </div>
        <div className='my-3 flex flex-row items-center rounded-lg bg-[#edf3f1] py-3'>
          <div className='bg-secondary-1 ml-2 flex h-[31px] w-[31px] items-center justify-center rounded px-4 font-bold text-white'>
            2
          </div>
          <div className='ml-4 text-left font-normal text-[#000000]'>
            {t('modal.check_message_and_click_link')}
          </div>
        </div>
      </div>
    );
  }

  if (step === 2 && tab === ETypeTab.SMS) {
    return (
      <div className='flex w-full flex-col'>
        <div className='my-5 flex w-full flex-row font-normal'>
          <Form
            layout='vertical'
            requiredMark={false}
            className='form-new w-full'
            onFinish={onFinishSMSStep2}
            colon={false}
          >
            <Form.Item
              label={t('modal.otp_label')}
              className='mb-3'
              validateFirst
              name='otp'
              rules={[
                { required: true, message: t('modal.otp_required') },
                {
                  pattern: /^[0-9-]+$/,
                  message: t('modal.otp_only_numbers'),
                },
              ]}
            >
              <Input
                inputMode='numeric'
                className='!text-16 !h-9'
                prefix={
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                  >
                    <path
                      d='M15.5 1H7.5C6.12 1 5 2.12 5 3.5V20.5C5 21.88 6.12 23 7.5 23H15.5C16.88 23 18 21.88 18 20.5V3.5C18 2.12 16.88 1 15.5 1ZM11.5 22C10.67 22 10 21.33 10 20.5C10 19.67 10.67 19 11.5 19C12.33 19 13 19.67 13 20.5C13 21.33 12.33 22 11.5 22ZM16 18H7V4H16V18Z'
                      fill='#626262'
                    />
                  </svg>
                }
                placeholder={t('modal.otp_placeholder')}
              />
            </Form.Item>
            <Form.Item
              className='mb-3'
              name='password'
              label={t('modal.password_label')}
              required={false}
              rules={[
                { required: true, message: t('modal.password_required') },
                {
                  min: 6,
                  message: t('modal.password_min_length'),
                },
              ]}
            >
              <Input.Password
                styles={{
                  input: {
                    paddingLeft: '4px',
                  },
                }}
                iconRender={(visible) => {
                  if (!visible) {
                    return (
                      <svg
                        className='mr-3'
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                      >
                        <g clipPath='url(#clip0_611_5518)'>
                          <path
                            d='M12 6.5C15.79 6.5 19.17 8.63 20.82 12C19.17 15.37 15.8 17.5 12 17.5C8.2 17.5 4.83 15.37 3.18 12C4.83 8.63 8.21 6.5 12 6.5ZM12 4.5C7 4.5 2.73 7.61 1 12C2.73 16.39 7 19.5 12 19.5C17 19.5 21.27 16.39 23 12C21.27 7.61 17 4.5 12 4.5ZM12 9.5C13.38 9.5 14.5 10.62 14.5 12C14.5 13.38 13.38 14.5 12 14.5C10.62 14.5 9.5 13.38 9.5 12C9.5 10.62 10.62 9.5 12 9.5ZM12 7.5C9.52 7.5 7.5 9.52 7.5 12C7.5 14.48 9.52 16.5 12 16.5C14.48 16.5 16.5 14.48 16.5 12C16.5 9.52 14.48 7.5 12 7.5Z'
                            fill='#8A8A8A'
                          />
                        </g>
                        <defs>
                          <clipPath id='clip0_611_5518'>
                            <rect width='24' height='24' fill='white' />
                          </clipPath>
                        </defs>
                      </svg>
                    );
                  }
                  return (
                    <svg
                      className='mr-3'
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                    >
                      <g clipPath='url(#clip0_659_5722)'>
                        <path
                          d='M12 5.99995C15.79 5.99995 19.17 8.12995 20.82 11.5C20.23 12.72 19.4 13.77 18.41 14.62L19.82 16.03C21.21 14.8 22.31 13.26 23 11.5C21.27 7.10995 17 3.99995 12 3.99995C10.73 3.99995 9.51 4.19995 8.36 4.56995L10.01 6.21995C10.66 6.08995 11.32 5.99995 12 5.99995ZM10.93 7.13995L13 9.20995C13.57 9.45995 14.03 9.91995 14.28 10.49L16.35 12.56C16.43 12.22 16.49 11.86 16.49 11.49C16.5 9.00995 14.48 6.99995 12 6.99995C11.63 6.99995 11.28 7.04995 10.93 7.13995ZM2.01 3.86995L4.69 6.54995C3.06 7.82995 1.77 9.52995 1 11.5C2.73 15.89 7 19 12 19C13.52 19 14.98 18.71 16.32 18.18L19.74 21.6L21.15 20.19L3.42 2.44995L2.01 3.86995ZM9.51 11.37L12.12 13.98C12.08 13.99 12.04 14 12 14C10.62 14 9.5 12.88 9.5 11.5C9.5 11.45 9.51 11.42 9.51 11.37V11.37ZM6.11 7.96995L7.86 9.71995C7.63 10.27 7.5 10.87 7.5 11.5C7.5 13.98 9.52 16 12 16C12.63 16 13.23 15.87 13.77 15.64L14.75 16.62C13.87 16.86 12.95 17 12 17C8.21 17 4.83 14.87 3.18 11.5C3.88 10.07 4.9 8.88995 6.11 7.96995Z'
                          fill='#626262'
                        />
                      </g>
                      <defs>
                        <clipPath id='clip0_659_5722'>
                          <rect width='24' height='24' fill='white' />
                        </clipPath>
                      </defs>
                    </svg>
                  );
                }}
                prefix={
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='16'
                    height='21'
                    viewBox='0 0 16 21'
                    fill='none'
                  >
                    <path
                      d='M14 7H13V5C13 2.24 10.76 0 8 0C5.24 0 3 2.24 3 5V7H2C0.9 7 0 7.9 0 9V19C0 20.1 0.9 21 2 21H14C15.1 21 16 20.1 16 19V9C16 7.9 15.1 7 14 7ZM5 5C5 3.34 6.34 2 8 2C9.66 2 11 3.34 11 5V7H5V5ZM14 19H2V9H14V19ZM8 16C9.1 16 10 15.1 10 14C10 12.9 9.1 12 8 12C6.9 12 6 12.9 6 14C6 15.1 6.9 16 8 16Z'
                      fill='#626262'
                    />
                  </svg>
                }
                visibilityToggle
                placeholder='••••••'
                className='!text-16 !h-9'
                height='48'
                type='password'
              />
            </Form.Item>
            <Form.Item
              className='mb-3'
              name='confirmPassword'
              label={t('modal.confirm_password_label')}
              required={false}
              rules={[
                {
                  required: true,
                  message: t('modal.confirm_password_required_message'),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(t('modal.confirm_password_not_match'))
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                styles={{
                  input: {
                    paddingLeft: '4px',
                  },
                }}
                iconRender={(visible) => {
                  if (!visible) {
                    return (
                      <svg
                        className='mr-3'
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                      >
                        <g clipPath='url(#clip0_611_5518)'>
                          <path
                            d='M12 6.5C15.79 6.5 19.17 8.63 20.82 12C19.17 15.37 15.8 17.5 12 17.5C8.2 17.5 4.83 15.37 3.18 12C4.83 8.63 8.21 6.5 12 6.5ZM12 4.5C7 4.5 2.73 7.61 1 12C2.73 16.39 7 19.5 12 19.5C17 19.5 21.27 16.39 23 12C21.27 7.61 17 4.5 12 4.5ZM12 9.5C13.38 9.5 14.5 10.62 14.5 12C14.5 13.38 13.38 14.5 12 14.5C10.62 14.5 9.5 13.38 9.5 12C9.5 10.62 10.62 9.5 12 9.5ZM12 7.5C9.52 7.5 7.5 9.52 7.5 12C7.5 14.48 9.52 16.5 12 16.5C14.48 16.5 16.5 14.48 16.5 12C16.5 9.52 14.48 7.5 12 7.5Z'
                            fill='#8A8A8A'
                          />
                        </g>
                        <defs>
                          <clipPath id='clip0_611_5518'>
                            <rect width='24' height='24' fill='white' />
                          </clipPath>
                        </defs>
                      </svg>
                    );
                  }
                  return (
                    <svg
                      className='mr-3'
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                    >
                      <g clipPath='url(#clip0_659_5722)'>
                        <path
                          d='M12 5.99995C15.79 5.99995 19.17 8.12995 20.82 11.5C20.23 12.72 19.4 13.77 18.41 14.62L19.82 16.03C21.21 14.8 22.31 13.26 23 11.5C21.27 7.10995 17 3.99995 12 3.99995C10.73 3.99995 9.51 4.19995 8.36 4.56995L10.01 6.21995C10.66 6.08995 11.32 5.99995 12 5.99995ZM10.93 7.13995L13 9.20995C13.57 9.45995 14.03 9.91995 14.28 10.49L16.35 12.56C16.43 12.22 16.49 11.86 16.49 11.49C16.5 9.00995 14.48 6.99995 12 6.99995C11.63 6.99995 11.28 7.04995 10.93 7.13995ZM2.01 3.86995L4.69 6.54995C3.06 7.82995 1.77 9.52995 1 11.5C2.73 15.89 7 19 12 19C13.52 19 14.98 18.71 16.32 18.18L19.74 21.6L21.15 20.19L3.42 2.44995L2.01 3.86995ZM9.51 11.37L12.12 13.98C12.08 13.99 12.04 14 12 14C10.62 14 9.5 12.88 9.5 11.5C9.5 11.45 9.51 11.42 9.51 11.37V11.37ZM6.11 7.96995L7.86 9.71995C7.63 10.27 7.5 10.87 7.5 11.5C7.5 13.98 9.52 16 12 16C12.63 16 13.23 15.87 13.77 15.64L14.75 16.62C13.87 16.86 12.95 17 12 17C8.21 17 4.83 14.87 3.18 11.5C3.88 10.07 4.9 8.88995 6.11 7.96995Z'
                          fill='#626262'
                        />
                      </g>
                      <defs>
                        <clipPath id='clip0_659_5722'>
                          <rect width='24' height='24' fill='white' />
                        </clipPath>
                      </defs>
                    </svg>
                  );
                }}
                prefix={
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='16'
                    height='21'
                    viewBox='0 0 16 21'
                    fill='none'
                  >
                    <path
                      d='M14 7H13V5C13 2.24 10.76 0 8 0C5.24 0 3 2.24 3 5V7H2C0.9 7 0 7.9 0 9V19C0 20.1 0.9 21 2 21H14C15.1 21 16 20.1 16 19V9C16 7.9 15.1 7 14 7ZM5 5C5 3.34 6.34 2 8 2C9.66 2 11 3.34 11 5V7H5V5ZM14 19H2V9H14V19ZM8 16C9.1 16 10 15.1 10 14C10 12.9 9.1 12 8 12C6.9 12 6 12.9 6 14C6 15.1 6.9 16 8 16Z'
                      fill='#626262'
                    />
                  </svg>
                }
                placeholder='••••••'
                visibilityToggle
                className='!text-16 !h-9 text-center'
                height='48'
                type='password'
              />
            </Form.Item>
            <div className='mt-5'>
              <Form.Item className='!my-auto w-full'>
                <ButtonCore
                  loading={loading}
                  type='primary'
                  size='large'
                  htmlType='submit'
                  className='w-full'
                  label={t('modal.save_password_button_label')}
                />
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    );
  }
  //   if (step === 2 && tab === ETypeTab.TELE) {
  //     return (
  //       <div className=''>
  //         <div className='my-2 flex flex-row items-center rounded-lg bg-[#edf3f1] py-3'>
  //           <div className='ml-2 flex h-[31px] w-[31px] items-center justify-center rounded bg-[#42b883] px-4 py-3 font-bold text-white'>
  //             1
  //           </div>
  //           <div className='ml-4 text-left font-normal text-[#000000]'>
  //             Mã OTP sẽ được gửi vào Telegram của bạn
  //           </div>
  //         </div>
  //         <div className='my-3 flex flex-row items-center rounded-lg bg-[#edf3f1] py-3'>
  //           <div className='ml-2 flex h-[31px] w-[31px] items-center justify-center rounded bg-[#42b883] px-4 font-bold text-white'>
  //             2
  //           </div>
  //           <div className='ml-4 text-left font-normal text-[#000000]'>
  //             Nhập mã xác minh được cung cấp qua Telegram vào trường nhập bên dưới
  //           </div>
  //         </div>
  //         <Form
  //           name='normal_login_forgot'
  //           className='form-new'
  //           onFinish={onFinishStep2}
  //           validateTrigger='onBlur'
  //         >
  //           <Form.Item
  //             className='!mb-6'
  //             name='otp'
  //             rules={[{ required: true, message: 'Vui lòng nhập OTP!' }]}
  //           >
  //             <Input               className='!text-16 !h-9' placeholder='Nhập mã OTP tại đây' />
  //           </Form.Item>
  //           <div className='flex flex-row gap-x-2'>
  //             <Form.Item className='w-1/2'>
  //               <ButtonCore
  //                 loading={loading}
  //                 type='primary'
  //                 htmlType='submit'
  //                 size='large'
  //                 label='Xác minh'
  //                 className='w-full'
  //               />
  //             </Form.Item>
  //             <div className='w-1/2'>
  //               <ButtonCore
  //                 type='secondary'
  //                 onClick={() => {
  //                   setStep(1);
  //                 }}
  //                 size='large'
  //                 label='Xác minh sau'
  //                 className='w-full'
  //               />
  //             </div>
  //           </div>
  //         </Form>
  //       </div>
  //     );
  //   }

  return (
    <div className='flex flex-col md:h-full'>
      <Form
        name='forgot_login'
        className='form-new w-full md:mb-6 md:flex md:h-full md:flex-col'
        validateTrigger='onBlur'
        onFinish={onFinish}
        form={form}
        layout='vertical'
        requiredMark={false}
      >
        <Form.Item
          label={
            <div className='text-12 text-white opacity-70'>
              {t('modal.User_name')}
            </div>
          }
          labelCol={{
            className: '!-mb-1',
          }}
          name='username'
          className='!mb-3'
          rules={[
            { required: true, message: t('modal.Please_enter_username') },
            {
              min: 6,
              message: t('modal.Username_must_be_greater_than_6_characters'),
            },
            {
              max: 22,
              message: t('modal.Username_must_be_less_than_50_characters'),
            },
            {
              pattern: /^[A-z0-9-]+$/,
              message: t('modal.Please_do_not_enter_special_characters'),
            },
          ]}
        >
          <Input
            className='!text-16 h-9'
            placeholder={t('modal.User_name_placeholder')}
          />
        </Form.Item>
        {tab === ETypeTab.SMS && (
          <Form.Item
            label={
              <div className='text-12 text-white opacity-70'>
                {t('modal.Phone_Number')}
              </div>
            }
            labelCol={{
              className: '!-mb-1',
            }}
            name='phone'
            rules={[
              {
                required: true,
                message: t('modal.Please_enter_phone_number!'),
              },
              {
                pattern: /^[0-9]+$/,
                message: t('modal.Please_enter_numbers_only'),
              },
              {
                min: 9,
                message: t('modal.Phone_number_min_length'),
              },
              () => {
                return {
                  validator(_, value) {
                    // 9 số
                    // 123456789 -> 0 123456789 // ko có 0 -> auto add 0 ở đầu
                    // 012345678 -> 0 12345678 //  có 0 -> báo sai format

                    // 10 số
                    // 1234567899 -> 12345678 //  ko có 0 -> báo sai format
                    // 0123456789 -> 0 123456789 //  có 0 -> ok

                    if (`${value}`.length === 9) {
                      if (`${value}`[0] === '0') {
                        return Promise.reject(
                          new Error(t('modal.invalid_format_phone'))
                        );
                      }
                    }

                    if (`${value}`.length === 10) {
                      if (`${value}`[0] !== '0') {
                        return Promise.reject(
                          new Error(t('modal.invalid_format_phone'))
                        );
                      }
                    }

                    return Promise.resolve();
                  },
                };
              },
            ]}
            className='!mb-4'
          >
            <Input
              maxLength={10}
              prefix='+66'
              inputMode='numeric'
              className='!text-16 h-9'
              placeholder={t('modal.Phone_number_placeholder')}
            />
          </Form.Item>
        )}

        <Form.Item className='!mb-0 !mt-12 text-center md:!mt-auto'>
          <ButtonCore
            loading={loading}
            type='primary'
            htmlType='submit'
            size='large'
            className='!primary-btn m-auto w-[140px]'
            label={tab === ETypeTab.SMS ? 'Next' : 'Gửi OTP'}
          />
        </Form.Item>
      </Form>
    </div>
  );
}

export default ForgotPassMobileForm;
