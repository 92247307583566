import { setCookie } from 'cookies-next';

import { TUser } from '@/lib/dto';

export const setAuth = (data: TUser): void => {
  setCookie('token', data?.token, {
    secure: true,
    sameSite: 'strict',
    // httpOnly: true,
  });
  setCookie('refresh_token', data?.refresh_token, {
    secure: true,
    sameSite: 'strict',
  });
};
