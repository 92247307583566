import { Checkbox, Form, Input } from 'antd';
import clsx from 'clsx';

import { checkSpecialCharacter } from '@/lib/validator';

import ButtonCore from '@/components/core/Button';
import useClientTranslation from '@/components/hooks/useClientTranslation';

const RegisterForm = (props: any) => {
  const { onFinishRegister, loading, refId, className } = props;
  const { t, currentLink } = useClientTranslation('HomePage');

  return (
    <div
      className={clsx(
        'form-login-modal overflow-y-auto overflow-x-hidden rounded-b-xl bg-[#FFF] md:flex md:flex-row md:rounded-xl',
        className
      )}
    >
      <div className='w-full md:w-full md:pt-3'>
        <Form
          layout='vertical'
          className='md:flex md:h-full md:flex-col md:px-8 md:pb-0'
          initialValues={{
            agreement: true,
            ref_id: refId,
          }}
          onFinish={onFinishRegister}
          validateTrigger='onBlur'
          requiredMark={false}
        >
          <Form.Item
            className='!mb-2'
            label={
              <div className='text-12  text-white opacity-70'>
                {t('modal.User_name')}
              </div>
            }
            labelCol={{
              className: '!-mb-1.5',
            }}
            validateFirst
            name='username'
            normalize={(value) => value.trim()}
            rules={[
              {
                required: true,
                message: t('modal.Please_enter_your_username'),
              },
              {
                min: 6,
                message: t('modal.Username_must_be_greater_than_6_characters'),
              },
              {
                max: 30,
                message: t('modal.Username_must_be_less_than_50_characters'),
              },
              () => ({
                validator: checkSpecialCharacter,
                message: t('modal.Please_do_not_enter_special_characters'),
              }),
            ]}
          >
            <Input
              className='!text-16 h-10 !rounded-lg'
              placeholder={t('modal.Username_must_6_-_22_characters')}
            />
          </Form.Item>
          <Form.Item
            normalize={(value) => value.trim()}
            label={
              <div className='text-12  text-white opacity-70'>
                {t('modal.Password')}
              </div>
            }
            labelCol={{
              className: '!-mb-1.5',
            }}
            className='!mb-2'
            name='password'
            rules={[
              { required: true, message: t('modal.Please_enter_a_password') },
              {
                min: 6,
                message: t('modal.Password_must_be_greater_than_6_characters'),
              },
            ]}
          >
            <Input.Password
              iconRender={(visible) => {
                if (!visible) {
                  return (
                    <svg
                      className='mr-3'
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                    >
                      <g clipPath='url(#clip0_611_5518)'>
                        <path
                          d='M12 6.5C15.79 6.5 19.17 8.63 20.82 12C19.17 15.37 15.8 17.5 12 17.5C8.2 17.5 4.83 15.37 3.18 12C4.83 8.63 8.21 6.5 12 6.5ZM12 4.5C7 4.5 2.73 7.61 1 12C2.73 16.39 7 19.5 12 19.5C17 19.5 21.27 16.39 23 12C21.27 7.61 17 4.5 12 4.5ZM12 9.5C13.38 9.5 14.5 10.62 14.5 12C14.5 13.38 13.38 14.5 12 14.5C10.62 14.5 9.5 13.38 9.5 12C9.5 10.62 10.62 9.5 12 9.5ZM12 7.5C9.52 7.5 7.5 9.52 7.5 12C7.5 14.48 9.52 16.5 12 16.5C14.48 16.5 16.5 14.48 16.5 12C16.5 9.52 14.48 7.5 12 7.5Z'
                          fill='#8A8A8A'
                        />
                      </g>
                      <defs>
                        <clipPath id='clip0_611_5518'>
                          <rect width='24' height='24' fill='white' />
                        </clipPath>
                      </defs>
                    </svg>
                  );
                }
                return (
                  <svg
                    className='mr-3'
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                  >
                    <g clipPath='url(#clip0_659_5722)'>
                      <path
                        d='M12 5.99995C15.79 5.99995 19.17 8.12995 20.82 11.5C20.23 12.72 19.4 13.77 18.41 14.62L19.82 16.03C21.21 14.8 22.31 13.26 23 11.5C21.27 7.10995 17 3.99995 12 3.99995C10.73 3.99995 9.51 4.19995 8.36 4.56995L10.01 6.21995C10.66 6.08995 11.32 5.99995 12 5.99995ZM10.93 7.13995L13 9.20995C13.57 9.45995 14.03 9.91995 14.28 10.49L16.35 12.56C16.43 12.22 16.49 11.86 16.49 11.49C16.5 9.00995 14.48 6.99995 12 6.99995C11.63 6.99995 11.28 7.04995 10.93 7.13995ZM2.01 3.86995L4.69 6.54995C3.06 7.82995 1.77 9.52995 1 11.5C2.73 15.89 7 19 12 19C13.52 19 14.98 18.71 16.32 18.18L19.74 21.6L21.15 20.19L3.42 2.44995L2.01 3.86995ZM9.51 11.37L12.12 13.98C12.08 13.99 12.04 14 12 14C10.62 14 9.5 12.88 9.5 11.5C9.5 11.45 9.51 11.42 9.51 11.37V11.37ZM6.11 7.96995L7.86 9.71995C7.63 10.27 7.5 10.87 7.5 11.5C7.5 13.98 9.52 16 12 16C12.63 16 13.23 15.87 13.77 15.64L14.75 16.62C13.87 16.86 12.95 17 12 17C8.21 17 4.83 14.87 3.18 11.5C3.88 10.07 4.9 8.88995 6.11 7.96995Z'
                        fill='#626262'
                      />
                    </g>
                    <defs>
                      <clipPath id='clip0_659_5722'>
                        <rect width='24' height='24' fill='white' />
                      </clipPath>
                    </defs>
                  </svg>
                );
              }}
              visibilityToggle
              className='!text-16 h-10 !rounded-lg'
              type='password'
              placeholder={t('modal.Password_minimum_6_characters')}
            />
          </Form.Item>
          <Form.Item
            normalize={(value) => {
              // if (value[0] === '0') return `${value}`.slice(1);

              return value.trim();
            }}
            label={
              <div className='text-12  text-white opacity-70'>
                {t('modal.Phone_Number')}
              </div>
            }
            labelCol={{
              className: '!-mb-1.5',
            }}
            className='!mb-2'
            validateFirst
            name='phone'
            rules={[
              {
                required: true,
                message: t('modal.Please_enter_phone_number!'),
              },
              {
                pattern: /^[0-9]+$/,
                message: t('modal.Please_enter_numbers_only'),
              },
              {
                min: 9,
                message: t(
                  'modal.Phone_number_must_not_be_less_than_9_characters'
                ),
              },
              {
                max: 10,
                message: t(
                  'modal.Phone_number_must_not_be_less_than_9_characters'
                ),
              },
              () => {
                return {
                  validator(_, value) {
                    // 9 số
                    // 123456789 -> 0 123456789 // ko có 0 -> auto add 0 ở đầu
                    // 012345678 -> 0 12345678 //  có 0 -> báo sai format

                    // 10 số
                    // 1234567899 -> 12345678 //  ko có 0 -> báo sai format
                    // 0123456789 -> 0 123456789 //  có 0 -> ok

                    if (`${value}`.length === 9) {
                      if (`${value}`[0] === '0') {
                        return Promise.reject(
                          new Error(t('modal.invalid_format_phone'))
                        );
                      }
                    }

                    if (`${value}`.length === 10) {
                      if (`${value}`[0] !== '0') {
                        return Promise.reject(
                          new Error(t('modal.invalid_format_phone'))
                        );
                      }
                    }

                    return Promise.resolve();
                  },
                };
              },
            ]}
          >
            <Input
              maxLength={10}
              prefix='+66'
              inputMode='numeric'
              className='!text-16 h-10 !rounded-lg'
              placeholder='xxx-xxx-xxx'
            />
          </Form.Item>
          {/* <Form.Item
            normalize={(value) => {
              return value.trim();
            }}
            label={
              <div className='text-12 text-white opacity-70'>
                {t('modal.Email')}
              </div>
            }
            labelCol={{
              className: '!-mb-1.5',
            }}
            className='!mb-2'
            validateFirst
            name='email'
            rules={[
              {
                required: true,
                message: t('modal.Please_enter_phone_number!'),
              },
              {
                required: true,
                type: 'email',
                message: t('modal.invalid_format_email'),
              },
              {
                max: 40,
                message: t('modal.emailMaxLength'),
              },
            ]}
          >
            <Input
              maxLength={40}
              className='!text-16 h-10 !rounded-lg'
              placeholder='abc@email.com'
            />
          </Form.Item> */}
          <div className='mx-auto flex w-full justify-center py-1.5 pt-4 text-center'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='160'
              height='2'
              viewBox='0 0 160 2'
              fill='none'
            >
              <path d='M0 1H160' stroke='url(#paint0_linear_5419_19935)' />
              <defs>
                <linearGradient
                  id='paint0_linear_5419_19935'
                  x1='0'
                  y1='3.04563'
                  x2='162.5'
                  y2='3.04563'
                  gradientUnits='userSpaceOnUse'
                >
                  <stop stop-color='#FFEAA1' stop-opacity='0' />
                  <stop offset='0.52' stop-color='#FFF3CB' />
                  <stop offset='1' stop-color='#FFEAA1' stop-opacity='0' />
                </linearGradient>
              </defs>
            </svg>
          </div>
          <Form.Item
            normalize={(value) => value.trim()}
            label={
              <div className='text-12  text-white opacity-70'>
                {t('modal.Referral_code')}
              </div>
            }
            labelCol={{
              className: '!-mb-1.5',
            }}
            className='!mb-3'
            name='ref_id'
            rules={[
              {
                max: 29,
                message: t(
                  'modal.Referral_code_cannot_be_more_than_29_characters'
                ),
              },
            ]}
          >
            <Input
              disabled={!!refId}
              className='!text-16 h-10 !rounded-lg'
              placeholder='Referral code (if any)'
            />
          </Form.Item>
          <Form.Item
            validateTrigger='onSubmit'
            className='!-mt-1 mb-6 md:!mb-4 md:!ml-0'
            name='agreement'
            valuePropName='checked'
            rules={[
              {
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error(
                          t('modal.You_must_accept_the_terms_to_continue!')
                        )
                      ),
              },
            ]}
          >
            <Checkbox>
              <div className='text-10 !mr-60 !mt-3 w-full tracking-[-0.1px] text-white opacity-[0.8] '>
                {t('modal.Byclicking')}
                <div>
                  <a
                    className='!text-main-bg !underline'
                    href={
                      currentLink('/information/terms-and-conditions') as string
                    }
                    target='_blank'
                  >
                    {t('modal.terms_and_conditions')}
                  </a>{' '}
                </div>
              </div>
            </Checkbox>
          </Form.Item>
          <Form.Item className='!mb-4 md:!mb-0 md:mt-auto'>
            <div className='flex flex-col'>
              <ButtonCore
                loading={loading}
                type='primary'
                htmlType='submit'
                className='md:!text-13 vw:!text-14 !primary-btn w-full !py-[11px] !text-black'
                size='large'
                label={t('modal.Register')}
              />
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default RegisterForm;
