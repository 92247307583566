import(/* webpackMode: "eager" */ "/home/msservice/public_html/fe-siam/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/home/msservice/public_html/fe-siam/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-no-ssr.js");
import(/* webpackMode: "eager" */ "/home/msservice/public_html/fe-siam/node_modules/next/font/google/target.css?{\"path\":\"src/app/[lng]/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"inter\"}");
import(/* webpackMode: "eager" */ "/home/msservice/public_html/fe-siam/node_modules/next/font/local/target.css?{\"path\":\"src/app/[lng]/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../../public/fonts/thai/Greycliff_Thai_CF_Regular.otf\",\"weight\":\"400\"},{\"path\":\"../../../public/fonts/thai/Greycliff_Thai_CF_Medium.otf\",\"weight\":\"500\"},{\"path\":\"../../../public/fonts/thai/Greycliff_Thai_CF_Demi_Bold.otf\",\"weight\":\"600\"},{\"path\":\"../../../public/fonts/thai/Greycliff_Thai_CF_Bold.otf\",\"weight\":\"700\"},{\"path\":\"../../../public/fonts/thai/Greycliff_Thai_CF_Ex_Bold.otf\",\"weight\":\"700\"},{\"path\":\"../../../public/fonts/thai/Greycliff_Thai_CF_Heavy.otf\",\"weight\":\"800\"}]}],\"variableName\":\"thaiFont\"}");
import(/* webpackMode: "eager" */ "/home/msservice/public_html/fe-siam/src/app/[lng]/(home)/components/Mobile/components/Renderer/RenderCateItemGames.tsx");
import(/* webpackMode: "eager" */ "/home/msservice/public_html/fe-siam/src/app/[lng]/(home)/components/Mobile/components/Renderer/RenderProviderGames.tsx");
import(/* webpackMode: "eager" */ "/home/msservice/public_html/fe-siam/src/app/[lng]/(home)/components/Mobile/components/SportGames/SportGames.tsx");
import(/* webpackMode: "eager" */ "/home/msservice/public_html/fe-siam/src/components/core/AntdRegistry.tsx");
import(/* webpackMode: "eager" */ "/home/msservice/public_html/fe-siam/src/components/modules/Modals/GameContainer/GameContainer.tsx");
import(/* webpackMode: "eager" */ "/home/msservice/public_html/fe-siam/src/components/modules/Modals/LoginModal/LoginModal.tsx");
import(/* webpackMode: "eager" */ "/home/msservice/public_html/fe-siam/src/components/modules/NextTopLoader/NextTopLoader.tsx");
import(/* webpackMode: "eager" */ "/home/msservice/public_html/fe-siam/src/components/modules/Page/Page.tsx")