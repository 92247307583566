'use client';

import { createCache, extractStyle, StyleProvider } from '@ant-design/cssinjs';
import type Entity from '@ant-design/cssinjs/es/Cache';
import { useServerInsertedHTML } from 'next/navigation';
import React from 'react';

interface StyledRegistryProps {
  children: React.ReactNode;
}

const StyledComponentsRegistry = ({ children }: StyledRegistryProps) => {
  const cache = React.useMemo<Entity>(() => createCache(), []);
  useServerInsertedHTML(() => (
    <style
      id='antd'
      dangerouslySetInnerHTML={{ __html: extractStyle(cache, true) }}
    />
  ));
  return (
    <StyleProvider hashPriority='high' cache={cache}>
      {children}
    </StyleProvider>
  );
};

export default StyledComponentsRegistry;
