/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
'use client';

import useClientTranslation from '@/components/hooks/useClientTranslation';

export const dynamic = 'force-dynamic';

import { message } from 'antd';
import clsx from 'clsx';
import { deleteCookie, getCookie, setCookie } from 'cookies-next';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';
import useSWR from 'swr';

import {
  login,
  logout,
  profile,
  register,
  trackDataCampaign,
} from '@/lib/apis';
import { setAuth } from '@/lib/cookie';
import Notification from '@/lib/notification';
import { formatThaiPhoneNumber, toVW } from '@/lib/utils';

import ButtonCore from '@/components/core/Button';
import MyModalDrawer from '@/components/core/ModalResponsive';
import useIsMobile from '@/components/hooks/useScreenSize';
import ForgetForm from '@/components/modules/Forms/ForgetForm';
import LoginForm from '@/components/modules/Forms/LoginForm';
import RegisterForm from '@/components/modules/Forms/RegisterForm';

enum EPopupType {
  Close = 0,
  Login = 1,
  Register = 2,
  Forgot = 3,
}
declare global {
  interface Window {
    popup?: {
      register: () => void;
      login: () => void;
      logout: (callback?: () => void) => void;
      closeModal: () => void;
      isModalOpen?: EPopupType;
      // Thêm các thuộc tính và phương thức khác theo nhu cầu của bạn
    };
    homePage?: any;
    gameContainer?: {
      openGameModal: (url: string) => void;
      closeGameModal: () => void;
    };
    NanoPlayer: any; // Thay 'any' bằng kiểu dữ liệu cụ thể nếu có
    deferredPrompt?: any;
  }
}

const LoginModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(EPopupType.Close);
  const paramsURL = useSearchParams();
  const initToken = getCookie('token');
  const router = useRouter();
  const pathname = usePathname();
  const popup = paramsURL.get('popup');
  // const { mutate } = useSWRConfig();
  const { mutate } = useSWR(initToken ? '/profile' : null, () => profile());

  const isMobile = useIsMobile();
  const { t, currentLink } = useClientTranslation('HomePage');

  const showModalLogin = () => {
    const token = getCookie('token');
    if (token) {
      return;
    }
    setIsModalOpen(EPopupType.Login);
  };
  const showModalRegister = () => {
    const token = getCookie('token');
    if (token) {
      return;
    }
    setIsModalOpen(EPopupType.Register);
  };

  const showModalForgot = () => {
    setIsModalOpen(EPopupType.Forgot);
  };
  const handleOk = () => {
    setIsModalOpen(EPopupType.Close);
  };

  const handleCancel = () => {
    if (popup) router.replace(pathname);
    setIsModalOpen(0);
  };

  const [loading, setLoading] = useState(false);
  const onFinishRegister = async (values: any) => {
    setLoading(true);
    const { phone } = values || {};

    const params = {
      currency: 'THB',
      ...values,
      fullname: '',
      phone_code: '+66',
      // phone: '',
      phone: formatThaiPhoneNumber(phone, '0'),
    };

    const { data, code, msg } = await register(params);
    if (code === 200) {
      setAuth(data);
      // Notification.success({
      //   text: t('modal.sign_up_success'),
      //   timer: 1500,
      // });
      Notification.popup({
        text: t('modal.sign_up_success'),
        // text: t('modal.Loggedinsuccessfully'),
        timer: 1500,
        description: t('modal.Loggedinsuccessfully'),
        img: <img src='/img/modal/success.svg' alt='' />,
        showConfirmButton: false,
        showCloseButton: false,
      });
      mutate();
      setTimeout(() => {
        router.push(pathname as string);
        setIsModalOpen(EPopupType.Close);
      }, 1800);
    } else {
      message.error(msg);
    }
    setLoading(false);
  };

  // useEffect(() => {
  // Notification.success({
  //   text: t('modal.Loggedinsuccessfully'),
  //   timer: 1500,
  // });
  // }, []);

  const onFinish = async (values: any) => {
    setLoading(true);
    try {
      const { data, code, msg } = await login({
        ...values,
      });
      if (code === 200) {
        const backCookie = getCookie('back_url');
        setAuth(data);
        Notification.popup({
          text: t('modal.Loggedinsuccessfully'),
          timer: 1500,
          description: t('modal.Loggedinsuccessfully'),
          img: <img src='/img/modal/success.svg' alt='' />,
          showConfirmButton: false,
          showCloseButton: false,
        });
        mutate();
        setTimeout(() => {
          if (backCookie) {
            deleteCookie('back_url');
            window.location.replace(backCookie || (currentLink('/') as string));
          } else {
            // router.push(pathname as string);
            setIsModalOpen(EPopupType.Close);
          }
        }, 1800);
      } else {
        message.error(msg);
      }
    } catch (error) {
      console.log('error:', error);
    }
    setLoading(false);
  };

  //   const { open } = useWeb3Modal();

  //   const connectWalletModal = () => {
  //     open();
  //     setIsModalOpen(EPopupType.Close);
  //   };

  const trackingUrl = async () => {
    await trackDataCampaign({
      aff_id: paramsURL.get('aff_id') || '',
      ref_id: paramsURL.get('ref_id') || '',
      user_id: paramsURL.get('ref_id') || '',
      utm: paramsURL.get('utm') || '',
      source: paramsURL.get('utm_source') || '',
      referrer: window.document.referrer || '',
    });
  };

  useEffect(() => {
    if (popup) {
      const token = getCookie('token');

      if (token) {
        return;
      }
      if (popup === 'login') {
        showModalLogin();
      }
      if (popup === 'register') {
        if (window.document.referrer) {
          setCookie('referrer', window.document.referrer, {
            maxAge: 2600000,
          });
        }
        if (paramsURL.get('ref_id')) {
          setCookie('ref_id', paramsURL.get('ref_id') || null, {
            maxAge: 2600000,
          });
          trackingUrl();
        }
        if (paramsURL.get('utm_source')) {
          setCookie('utm_source', paramsURL.get('utm_source') || null, {
            maxAge: 2600000,
          });
        }
        if (paramsURL.get('aff_id')) {
          setCookie('aff_id', paramsURL.get('aff_id') || null, {
            maxAge: 2600000,
          });
        }
        if (paramsURL.get('utm')) {
          setCookie('utm', paramsURL.get('utm') || null, {
            maxAge: 2600000,
          });
        }
        showModalRegister();
      }
    }
  }, [popup]);

  const handleLogout = async (callback?: (res?: any) => void) => {
    const res = await Notification.popup({
      title: t('modal.Logging_out'),
      description: t('modal.Do_you_want_to_logout'),
      showCloseButton: true,
      img: <img src='/img/modal/logout.png' alt='logout-icon' />,
    });
    const clearToken = () => {
      deleteCookie('token');
      deleteCookie('refresh_token');
      mutate();
      console.log('pathname:', pathname);
      router.replace(pathname as string);
      typeof callback === 'function' && callback(res);
    };
    if (res.isConfirmed) {
      try {
        await logout();
      } catch (error) {
        console.error('failure by Logout: ', error);
      } finally {
        clearToken();
      }
    }
  };

  useEffect(() => {
    const func = {
      login: showModalLogin,
      register: showModalRegister,
      logout: handleLogout,
      closeModal: handleCancel,
      isModalOpen,
    };
    window.popup = func;
  }, []);

  return (
    <div>
      <MyModalDrawer
        getContainer='body'
        destroyOnClose
        afterOpenChange={(open: boolean) => {
          if (!open && popup) router.replace(pathname);
        }}
        className={clsx(!isMobile && '!rounded-[16px]')}
        // mask={!isMobile}
        height={
          isMobile
            ? 'calc(100%)'
            : isModalOpen === EPopupType.Login
            ? 'auto'
            : 'auto'
        }
        key='bottom'
        placement='bottom'
        afterClose={() => {
          if (popup) router.replace(pathname);
        }}
        closeIcon={
          !isMobile && (
            <div className='flex !h-[22px] !w-[22px] items-center justify-center rounded-full bg-[#ffffff80]'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='12'
                height='12'
                viewBox='0 0 12 12'
                fill='none'
              >
                <path
                  d='M3.3536 2.64645C3.15834 2.45118 2.84176 2.45118 2.64649 2.64645C2.45123 2.84171 2.45123 3.15829 2.64649 3.35355L5.29292 5.99998L2.64645 8.64645C2.45118 8.84171 2.45118 9.15829 2.64645 9.35355C2.84171 9.54882 3.15829 9.54882 3.35355 9.35355L6.00002 6.70708L8.6465 9.35356C8.84176 9.54882 9.15834 9.54882 9.3536 9.35356C9.54886 9.1583 9.54886 8.84171 9.3536 8.64645L6.70713 5.99998L9.35355 3.35355C9.54882 3.15829 9.54882 2.84171 9.35355 2.64645C9.15829 2.45119 8.84171 2.45119 8.64645 2.64645L6.00002 5.29287L3.3536 2.64645Z'
                  fill='#F4F4F4'
                />
              </svg>
            </div>
          )
        }
        footer={null}
        width={isMobile ? 407 : 500}
        open={isModalOpen > EPopupType.Close}
        onOk={handleOk}
        onCancel={handleCancel}
        onClose={handleCancel}
        styles={{
          content: {
            height: '100%',
            backgroundColor: '#524733',
            backgroundImage:
              'url(/img/modal/modal-pattern.png),url(/img/modal/modal-banner.png)',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: isMobile
              ? `bottom, center ${toVW(0)}`
              : 'center bottom, center 2px, center center',
            backgroundSize: isMobile ? '100%' : '60%, 100%',
            padding: isMobile
              ? `${toVW(170)} ${toVW(24)} 0 ${toVW(24)}`
              : '210px 0 0 0',
            paddingBottom: 0,
            borderRadius: isMobile ? '0' : '16px',
            border: isMobile ? '' : '1px solid #FFF3CB',
          },
          body: {
            padding: isMobile ? `0 ${toVW(15)}` : '10px 50px 40px 50px',
            overflowX: 'hidden',
          },
        }}
      >
        <>
          {isMobile && (
            <div
              onClick={window?.popup?.closeModal}
              className='vw:right-[12px] vw:top-[20px] absolute flex !h-[24px] !w-[24px] items-center justify-center rounded-full bg-[#ffffff80]'
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='12'
                height='12'
                viewBox='0 0 12 12'
                fill='none'
              >
                <path
                  d='M3.3536 2.64645C3.15834 2.45118 2.84176 2.45118 2.64649 2.64645C2.45123 2.84171 2.45123 3.15829 2.64649 3.35355L5.29292 5.99998L2.64645 8.64645C2.45118 8.84171 2.45118 9.15829 2.64645 9.35355C2.84171 9.54882 3.15829 9.54882 3.35355 9.35355L6.00002 6.70708L8.6465 9.35356C8.84176 9.54882 9.15834 9.54882 9.3536 9.35356C9.54886 9.1583 9.54886 8.84171 9.3536 8.64645L6.70713 5.99998L9.35355 3.35355C9.54882 3.15829 9.54882 2.84171 9.35355 2.64645C9.15829 2.45119 8.84171 2.45119 8.64645 2.64645L6.00002 5.29287L3.3536 2.64645Z'
                  fill='#F4F4F4'
                />
              </svg>
            </div>
          )}
          {isModalOpen !== EPopupType.Forgot && (
            <div className='flex w-full items-center justify-center !rounded-t-lg !bg-[#ffffff0d]'>
              <ButtonCore
                onClick={showModalLogin}
                className={clsx(
                  'vw:w-[172px] vw:!h-[43px]  vw:!text-16 md:!text-16 !rounded-none !rounded-tl-lg !border-2 !border-l-0 !border-r-0 !border-t-0 !border-b-[#FFF3CB] !border-b-[#fff3cb26] !bg-transparent !font-semibold transition-all md:!h-[43px]',
                  isModalOpen === EPopupType.Login
                    ? '!bg-linear-14 !rounded-tr-lg !border-b-[#FFF3CB] !text-black'
                    : '!text-[#ffffffcc]'
                )}
              >
                {t('modal.Login')}
              </ButtonCore>
              <ButtonCore
                onClick={showModalRegister}
                className={clsx(
                  'vw:w-[172px] vw:!h-[43px]  vw:!text-16 md:!text-16 !rounded-none !rounded-tr-lg !border-2 !border-l-0 !border-r-0 !border-t-0 !border-b-[#FFF3CB] !border-b-[#fff3cb26] !bg-transparent !font-semibold transition-all md:!h-[43px]',
                  isModalOpen === EPopupType.Register
                    ? '!bg-linear-14 !rounded-tl-lg !border-b-[#FFF3CB] !text-black'
                    : '!text-[#ffffffcc]'
                )}
              >
                {t('modal.Register')}
              </ButtonCore>
            </div>
          )}
          <div>
            {/* {isModalOpen === EPopupType.Login && (
              <div>
                <span className='text-24 text-main-bg font-bold'>
                  {t('modal.Login')}
                </span>
              </div>
            )}
            {isModalOpen === EPopupType.Register && (
              <div>
                <span className='text-24 text-main-bg font-bold'>
                  {t('modal.Register')}
                </span>
              </div>
            )} */}
            {isModalOpen === EPopupType.Forgot && (
              <div>
                <span className='text-24 text-main-bg font-bold'>
                  {t('modal.Recovery_password')}
                </span>
              </div>
            )}
          </div>
          {isModalOpen === EPopupType.Login && (
            <LoginForm
              className='vw:mt-4 vw:px-0 bg-transparent md:mt-0 md:px-0'
              onFinish={onFinish}
              showModalForgot={showModalForgot}
              loading={loading}
              showModalRegister={showModalRegister}
            />
          )}
          {isModalOpen === EPopupType.Register && (
            <RegisterForm
              className='vw:mt-4 vw:px-0 bg-transparent md:mt-0 md:px-0'
              onFinishRegister={onFinishRegister}
              showModalLogin={showModalLogin}
              loading={loading}
              refId={paramsURL.get('ref_id') || getCookie('ref_id')}
            />
          )}
          {isModalOpen === EPopupType.Forgot && <ForgetForm />}
        </>
      </MyModalDrawer>
    </div>
  );
};

export default LoginModal;
